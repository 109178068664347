import { Atom } from ":mods";
import { createSignal, splitProps } from "solid-js";

export function ExhibitionSection(props) {
  const [local, others] = splitProps(props, ["exhibitionData"]);
  const textInputStyle = "p-20px border border-1px border#p border-solid";
  const exhibitionPDF = local.exhibitionData?.exhibition_pdf.split("exhibitions/")[1].split("?")[0];
  const [moduleOpen, setModuleOpen] = createSignal("0");
  const handleAccordionClicks = (id) => {
    if (moduleOpen() == id) {
      setModuleOpen("0");
      return;
    }
    setModuleOpen(id);
  };

  return (
    <>
      <p class="text-14px font-700 leading-21px mb-20px">Pre Foundation Exhibition Idea</p>
      <Atom.Foldable.Accordion
        arrowMode="end"
        iconClass={`w-24px h-24px  icon-local:down-arrow ${moduleOpen() == "1" && "!rotate-180"}`}
        onclick={() => handleAccordionClicks("1")}
        title={
          <section class="flex flex-row justify-start items-center w-full font-700">
            <span>{local.exhibitionData?.name} </span>
          </section>
        }
        class="border-2 border-solid border#p px-2 py-20px mb-20px"
      >
        <section class="flex flex-col gap-20px my-10px">
          <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">Title for the exhibition idea you want to develop :</p>
            <div class={textInputStyle}>{local.exhibitionData?.title}</div>
          </div>
          <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">What is the exhibition about?</p>
            <div class={textInputStyle}>{local.exhibitionData?.about}</div>
          </div>
          <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">
              who would be interested in attending and how would you tell the story in a museum space?
            </p>
            <div class={textInputStyle}>{local.exhibitionData?.interset}</div>
          </div>
          <div class="flex flex-col gap-10px">
            <p class="text-16px leading-22.4px">
              How would you allow visitors to experience the story, within a museum space?
            </p>
            <div class={textInputStyle}>{local.exhibitionData?.exhibition_experience}</div>
          </div>
        </section>
      </Atom.Foldable.Accordion>
      <Atom.Foldable.Accordion
        arrowMode="end"
        iconClass={`w-24px h-24px  icon-local:down-arrow ${moduleOpen() == "2" && "!rotate-180"}`}
        onclick={() => handleAccordionClicks("2")}
        title={
          <section class="flex flex-row justify-start items-center w-full font-700">
            <span>Attachments </span>
          </section>
        }
        class="border-2 border-solid border#p px-2 py-20px mb-20px"
      >
        <section class="flex flex-col gap-20px my-10px">
          <div class="flex flex-col gap-10px">
            <a href={local.exhibitionData?.exhibition_pdf} target={"_blank"} class={`${textInputStyle} w-max `}>
              {exhibitionPDF} (PDF)
            </a>
          </div>
        </section>
      </Atom.Foldable.Accordion>
    </>
  );
}
